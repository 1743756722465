$nav-breakpoint-max: "max-width: 1030px";
$nav-breakpoint-min: "min-width: 1031px";

/*********************
BUTTON
*********************/
@mixin button($bgColor: #ac8631, $color: #fff) {
    $colorHover: darken($color, 5%);
    $bgColorHover: darken($bgColor, 5%);

    display: inline-block;
    margin: 0 auto;
    padding: 1.3rem 2.5rem;
    text-align: center;
    color: $color;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    border-radius: 8px;
    border: 0;
    background-color: $bgColor;
    transition: background-color .2s;
    cursor: pointer;

    &:hover {
        color: $colorHover;
        text-decoration: none;
        background-color: $bgColorHover;
    }
}


/*********************
CLEAR FLOAT
*********************/
@mixin clear-float($where){
    &:#{$where}{
        clear: both;
        content: '';
        display: block;
        height: 0;
        visibility: hidden;
    }
}

/*********************
DISPLAY TABLE
*********************/
@mixin displaytable($tablemaw: 1200px, $leftcell: 50%, $rightcell: 50%, $valign: middle, $breakpoint: 900px){
    display: table;
    width: 100%;
    max-width: $tablemaw;
    margin: 0 auto;

    .left-cell,
    .right-cell {
        display: table-cell;
        vertical-align: $valign;
    }

    .left-cell {
        width: $leftcell;
        @media (max-width: $breakpoint){
            display: block;
            margin: 20px auto;
            width: 100%;
            max-width: 100%;
        }
    }

    .right-cell {
        width: $rightcell;
        @media (max-width: $breakpoint){
            display: block;
            margin: 20px auto;
            width: 100%;
            max-width: 100%;
        }
    }
}

/*********************
DISPLAY TABLE 3 COLUMN
*********************/
@mixin displaytable3col($tablemaw: 1200px, $leftcell: 33.33%, $middlecell: 33.33%, $rightcell: 33.33%, $valign: middle, $breakpoint: 900px){
    display: table;
    width: 100%;
    max-width: $tablemaw;
    margin: 0 auto;

    .left-cell,
    .middle-cell,
    .right-cell {
        display: table-cell;
        vertical-align: $valign;
    }

    .left-cell {
        width: $leftcell;
        @media (max-width: $breakpoint){
            display: block;
            width: auto;
            max-width: 100%;
            margin: 20px auto;
        }
    }

    .middle-cell {
        width: $middlecell;
        @media (max-width: $breakpoint){
            display: block;
            width: auto;
            max-width: 100%;
            margin: 20px auto;
        }
    }

    .right-cell {
        width: $rightcell;
        @media (max-width: $breakpoint){
            display: block;
            width: auto;
            max-width: 100%;
            margin: 20px auto;
        }
    }
}