// THEME IMAGES DIRECTORY

$wp-img-dir: '/wp-content/themes/fraserconstructionsandiego/library/images/';

// MEDIA min-width

$media-min-xs: 480px;
$media-min-sm: 576px;
$media-min-md: 768px;
$media-min-lg: 992px;
$media-min-xl: 1200px;

// MEDIA max-width

$media-max-xs: $media-min-sm - 1;
$media-max-sm: $media-min-md - 1;
$media-max-md: $media-min-lg - 1;
$media-max-lg: $media-min-xl - 1;

// FONT

$main-font: Roboto, Arial, sans-serif;

$font-size-base: 1.1rem;
$font-size-h1: 2.6rem;
$font-size-h2: 2.15rem;
$font-size-h3: 1.7rem;
$font-size-h4: 1.25rem;
$font-size-h5: $font-size-base;
$font-size-h6: 0.85rem;

// COLORS

$orange: #b28a33;
$blue: #2c4f98;
$blue-light: #648de7;

// JS-consumable MEDIA QUERY VALUES

.media-size {
    font-size:0;
    line-height: 0;
    color: rgba(0,0,0,0);

    &-xs {
        display: block;
        @media (min-width: $media-min-sm) {
            display: none;
        }
    }
    &-sm {
        display: none;
        @media (min-width: $media-min-sm) and (max-width: $media-min-md - 1) {
            display: block;
        }
    }
    &-md {
        display: none;
        @media (min-width: $media-min-md) and (max-width: $media-min-lg - 1) {
            display: block;
        }
    }
    &-lg {
        display: none;
        @media (min-width: $media-min-lg) and (max-width: $media-min-xl - 1) {
            display: block;
        }
    }
    &-xl {
        display: none;
        @media (min-width: $media-min-xl) {
            display: block;
        }
    }
}