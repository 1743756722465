@import "./base";

.page-template-template-home {
    .inner-content {
        padding: 0;
    }
}

.hero {
    position: relative;
    background-image: url("/wp-content/uploads/2017/02/deck-1-2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0,0,0,.58);
    }

    &-inner {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        position: relative;
        height: calc(100vh - 104px);
        margin: 0 auto;
        z-index: 1;

        @media (max-width: 960px) {
            padding: 6rem 2rem;
        }

        @media (max-width: 768px) {
            max-height: 500px;
            padding: 4rem 2rem;
        }
    }

    h3, p {
        max-width: 900px;
        margin: 0 auto 1rem;
    }

    h3 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1.2;
        color: #fff;

        @media (max-width: 960px) {
            font-size: 2.5rem;
        }

        @media (max-width: 600px) {
            font-size: 2rem;
        }
    }

    p {
        font-size: $font-size-base * 1.25;
        font-weight: 400;
        color: #fff;

        @media (max-width: 960px) {
            font-size: $font-size-base;
        }
    }
}

.value-props-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding: 20px 40px 40px;
    text-align: center;

    @media (max-width: 600px) {
        flex-direction: column;
        padding: 20px 0 40px;
    }
}


.value-prop {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 20px;
    width: 100%;
    max-width: 350px;
    text-align: center;
    background-color: #f6f6f6;
    box-shadow: 0px 0px 2px 1px rgba(190, 190, 190, 0.5);

    @media (max-width: 600px) {
        margin: 0 auto 20px;
    }

    &__image {
        display: block;
        width: 100%;
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &__description {
        flex: 2;
        margin-bottom: 0;
        padding: 2rem 2rem 1rem;
        font-size: 1.2rem;
    }

    &__button-wrap {
        display: block;
        width: 100%;
        padding: 1rem 2rem 2rem;
    }

    &__button {
        display: block;
        margin-bottom: .5rem;
        padding: .9rem 1rem;
    }

    &__contact-link {
        display: inline-block;
        padding: .5rem .5rem 0;
        text-decoration: none;
        font-style: italic;
        color: $orange;
        transition: all .2s;

        &:hover {
            color: darken($orange, 5%);
        }
    }
}
